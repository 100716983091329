<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "mǐfàn",
          hanzi: "米饭",
          english: "rice",
          image: require(`@/assets/img/12-Vocabulary/lesson-45-mifan-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "miàntiáor",
          hanzi: "面条儿",
          english: "noodle(s)",
          image: require(`@/assets/img/12-Vocabulary/lesson-45-miantiao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "dàngāo",
          hanzi: "蛋糕",
          english: "cake",
          image: require(`@/assets/img/12-Vocabulary/lesson-45-dangao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "zhēn ",
          hanzi: "真",
          english: "really",
          image: require(`@/assets/img/12-Vocabulary/lesson-45-zhen-bubble.svg`),
          blue: true,
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
