var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lesson-container" },
    [
      _c("DialogPage", {
        attrs: {
          bgImg: _vm.bgImg,
          pageInfoList: _vm.pageInfoList,
          buttonList: _vm.buttonList,
          clickIdList: _vm.clickIdList,
          lessonNo: 45,
          isAllScreen: true,
        },
        on: { clickTheBubble: _vm.clickTheBubble },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }