<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-45.mp3"),
      },
      timeStampList: [5.4, 7.5, 10.8, 13.3, 15.8, 22],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Bàba xǐhuan chī mǐfàn.",
            chineseWords: "爸爸喜欢吃米饭。",
          },
          {
            id: 2,
            spelling: "Gēge xǐhuan chī miàntiáor",
            chineseWords: "哥哥喜欢吃面条儿。",
          },
          {
            id: 3,
            spelling: "Nǐ xǐhuan chī shénme?",
            chineseWords: "你喜欢吃什么？",
          },
          {
            id: 4,
            spelling: "Wǒ xǐhuan chī dàngāo.",
            chineseWords: "我喜欢吃蛋糕。",
          },
          {
            id: 5,
            spelling: "Zhè ge dàngāo zhēn dà! Māma, nǐ zhēn hǎo!",
            chineseWords: "这个蛋糕真大！妈妈，你真好！",
          },
        ],
      },
    };
  },
};
</script>
