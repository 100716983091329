<template>
  <div class="game-container">
    <MagnifierGame
      :optionList="optionList"
      :bgImg="bgImg"
      :lessonNo="451"
      :title="title"
    />
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      title: {
        hanzi: "这是米饭。",
        pinyin: "Zhè shì mǐfàn.",
      },
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/book-background.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/rice-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/rice-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/rice-3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/rice-4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-45-1/rice-5.svg"),
        },
      ],
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
