<template>
  <div class="game-container">
    <SpeakingSentence
      :buttonList="buttonList"
      :gameList="gameList"
      @initGame="initGame"
      @showSentence="showSentence"
    />
  </div>
</template>

<script>
import SpeakingSentence from "@/components/Course/CoursePage/SpeakingSentence";
export default {
  data() {
    return {
      gameList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/image-1.svg`),
          pinyin: "Zhēn xiǎo!",
          hanzi: "真小！",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/image-2.svg`),
          pinyin: "Zhēn dà!",
          hanzi: "真大！",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/image-3.svg`),
          pinyin: "Zhēn gāo!",
          hanzi: "真高！",
          isShowSentence: false,
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/image-4.svg`),

          pinyin: "Zhēn dà!",
          hanzi: "真大！",
          isShowSentence: false,
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/menu-1.svg"),
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/menu-2.svg"),
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/menu-3.svg"),
          startNumberRange: 3,
          endNumberRange: 3,
        },
        {
          image: require("@/assets/img/16-GAMES/G28-click-reveal/lesson-45-1/menu-4.svg"),
          startNumberRange: 4,
          endNumberRange: 4,
        },
      ],
    };
  },
  components: {
    SpeakingSentence,
  },
  methods: {
    initGame() {
      this.gameList.forEach((item) => {
        item.isShowSentence = false;
      });
    },
    showSentence(index) {
      this.gameList[index - 1].isShowSentence = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
